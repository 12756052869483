<style lang="sass" scoped>
$primary: #007bff

// .tabs-status-flow
//   min-width: 100px
//   // color: tomato
//   width: 100px
//   float: left

//   .router-link-active > &
//     background-color: #fff
//     border: solid 1px #ccc
//     border-width: 0 1px 1px 1px

.input-field-overlay
  margin-top: -1.7rem
  float: left
  position: relative
  top: 1.7rem
  left: 0.5rem
.form-field
  border: 0
  border-bottom: solid 1px #f0f0f0
  font-weight: bold
  &:focus
    color: $primary
    box-shadow: none !important
    // border-bottom: solid 1px #ccc
// .item-hover-action-container
//   .item-hover-action
//     // display: none
.item-hover-action
  &:hover
    text-decoration: underline
    cursor: pointer
    // display: block !important
// .item-response-hover-action-container
//   .item-response-hover-action
//     // display: none
.item-response-hover-action
  &:hover
    text-decoration: underline
    cursor: pointer
.text-body
  text-overflow: ellipsis
  display: block
  white-space: nowrap
  height: 1.5rem
  overflow: hidden
  width: 300px

.text-multiline
  white-space: pre
  word-wrap: nowrap
.status-active
  font-weight: bold
  border: solid 1px $primary !important
  z-index: 2
// .order-tab-item
//   // font-size: 12px

.order-color-default
  color: #ccc
  &.order-color-active
    color: tomato
    font-weight: bold !important
    background-color: lighten(tomato, 30%) !important
    border-color: lighten(tomato, 20%) !important
    box-shadow: 0px 0px 10px -2px tomato

    .icon-light
      opacity: 1 !important
      filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%)

svg.icon-light
  filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%)


.text-sms-title
  font-size: 14px
  font-weight: bold
.text-sms-summary
  font-size: 12px
  font-weight: normal
  color: #888

  text-overflow: ellipsis
  display: block
  white-space: nowrap
  overflow: hidden
  // width: 300px

.card-checklist
  transition: border 300ms
  &:hover
    // box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important
    border-color: #777
    cursor: pointer
    // bottom: 3px
</style>
<template lang="pug">
div
  div.float-right(style='position: relative; top: -100px; right: 180px; margin-bottom: -100px')
    span(v-if='$refs.table && $refs.table.selectedRows')
      button.btn.border-0.btn-primary.btn-sm.shadow-sm.rounded-0(type='button' :disabled='$refs.table.selectedRows.length === 0' @click='remove_orders()')
        | 선택삭제
        span(v-show='$refs.table.selectedRows.length')  ({{$refs.table.selectedRows.length}}건)
    button.btn.border-0.btn-primary.btn-sm.shadow-sm.rounded-0.ml-1.mr-1(type='button' @click='add_orders()') 고객추가
  vue-good-table.table-default.table-scroll(
    ref='table'
    styleClass='vgt-table striped hover'
    :columns='columns'
    :rows='rows'
    :fixed-header='false'
    mode='remote'
    :select-options="selectOptions"
    :search-options="searchOptions"
    :pagination-options='paginationOptions'
    :totalRows='total_count'
    @on-search="onSearch"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-column-filter="onColumnFilter"
    @on-per-page-change="onPerPageChange"
    :sort-options='sortOptions'
    :isLoading.sync="isLoading"
  )
    //- template(slot='table-actions')

    template(slot='loadingContent')
      b-overlay(show variant='light' opacity='0.85' blur='0')
    template(slot='table-row' slot-scope='props')
      //- span.d-block.text-center(v-if='props.column.field==`action`' @click.prevent.stop='')
        //- a.p-2(href='#' @click.stop.prevent='open_order_detail(props.row)') 조회
        router-link.btn.btn-default.bg-white.rounded-0.text-primary.border(:to='`/property/${property_id}/order/${sf_id}/${props.row.id}`' style='padding: 5px 10px; font-size: 12px; margin-top: -7px; margin-bottom: -7px') 조회
      span(v-if='props.column.field==`id`') {{props.formattedRow[props.column.field]}}
      span(v-else-if='props.column.field==`status_text`')
        span.d-block(style='min-width: 100px') {{props.formattedRow[props.column.field]}}
      span(v-else-if='props.column.field==`name`')
        .column-text-body
          router-link.text-primary.column-link(:to='`/property/${property_id}/order/${sf_id}/${props.row.id}`') {{props.formattedRow[props.column.field]}}
      span(v-else)
        .column-text-body {{props.formattedRow[props.column.field]}}
  div(v-if='order_loaded')
    router-view(@open-modal='open_modal($event)' @close-modal='close_modal')

  modal.p-4.modal-opened(name='order-detail' @before-close='before_close($event)' @opened='opened()' width='800px' :height='`auto`' :scrollable='true' :focusTrap='true' )
    form(@submit.prevent='update_order(order)')
      .row(v-if='order.id')
        .col.border-right(style='padding-right:0; min-height: 380px')
          b-tabs(card v-model='order_detail_tab')
            b-tab.p-0
              template(#title): span(style='font-size: 12px') 고객
              div
                .btn-group.mt-2.btn-block.px-2
                  button.btn.border.py-2(type='button' @click='set_status(order, st)' v-for='st in status_flows' :class='{"text-primary status-active":(order.status_text == st)}' style='font-size: 12px; line-height:1rem') {{st}}
              div
                strong.text-muted.input-field-overlay: small 이름
                input.form-control.text-right.rounded-0.form-field(type='text' v-model='order.name' ref='order-input' @blur='form_touched')
              div
                strong.text-muted.input-field-overlay: small 주문번호
                input.form-control.text-right.rounded-0.form-field(type='text' v-model='order.order_code' @blur='form_touched')
              div
                strong.text-muted.input-field-overlay: small 연락처
                input.form-control.text-right.rounded-0.form-field(type='text' v-model='order.phone' @blur='form_touched')
              div
                strong.text-muted.input-field-overlay: small 이메일
                input.form-control.text-right.rounded-0.form-field(type='text' v-model='order.email' @blur='form_touched')
              div
                strong.text-muted.input-field-overlay: small 고객메모
                textarea.form-control.text-right.rounded-0(v-model='order.customer_memo' rows=3 @blur='form_touched')
              div
                strong.text-muted.input-field-overlay: small 주문메모
                textarea.form-control.text-right.rounded-0(v-model='order.memo' rows=3 @blur='form_touched')


            b-tab
              template(#title): span(style='font-size: 12px') 문자
              div
                //- pre {{sms_config}}
                .alert.alert-warning(v-show='!sms_config.numbers.length')
                  strong 알림
                  p 발신자 번호 등록이 필요합니다.
                  a(:href='`/property/${property_id}/manage/sms`' target='_blank') 새창으로 열기
                a.float-right(href='#' @click.prevent='load_sms()'): small 새로고침
                select.form-control(v-model='order.form.sms_sender_phone' :disabled='!sms_config.numbers.length')
                  option(v-for='config in sms_config.numbers' :value='config.number') {{config.number}}
                form(@submit.prevent.stop='send_sms(order)')
                  div
                    strong.text-muted: small 내용
                    Mentionable(:keys="['@', '#']" :items="sms_mention_items"
                      offset="6"
                      insert-space
                      @open="onOpen"
                    )
                      textarea.form-control.rounded-0.m-0(v-on:input="order.form.sms_input = $event.target.value" @keyup='sms_result()' rows=7 :disabled='!order.form.sms_sender_phone' @blur='form_touched')

                      template(#no-result="")
                        .dim
                          | No result
                      template(#item-@="{ item }")
                        .user
                          span ({{ item.label }})
                          //- | {{ item.value }}
                          //- span.dim
                          //-   | ({{ item.label }})
                      template(#item-#="{ item }")
                        .issue
                          //- span.number
                          //-   | \#{{ item.value }}
                          //- span.dim
                          //-   | {{ item.label }}
                          span {{ item.label }}
                  div
                    strong.text-muted.input-field-overlay: small 내용
                    textarea.form-control.rounded-0.pt-4.m-0(v-model='order.form.sms_result' rows=7 readonly)
                  button.btn.btn-block.bg-light.border(type='submit') 보내기
            b-tab
              template(#title): span(style='font-size: 12px') 이메일
              div
                form(@submit.prevent='send_email(order)')
                  input.form-control(v-model='order.form.subject' placeholder='제목' @blur='form_touched')
                  textarea.form-control(v-model='order.form.email' rows=5 placeholder='본문' @blur='form_touched')
                  button.btn.btn-block.bg-light.border(type='submit') 보내기
            //- b-tab
              template(#title): span(style='font-size: 12px') 메모
              div
                form(@submit.prevent='send_memo(order)')
                  textarea.form-control(v-model='order.form.memo' style='max-width: 300px' rows=5)
                  button.btn.btn-block.bg-light.border(type='submit') 추가

        .col.async(style='background-color: #f2f4f6; min-height: 300px; overflow: scroll; overflow-x: hidden; overflow-y: scroll; ' :class='{done:order.history}')
          div(v-if='order.history' style='height: 50vh; overflow: scroll;')
            small {{order.history.length}}건
            div(v-for='(history, $index) in order.history')
              div(v-if='history.category == `memo`' @click.stop='' :class=`{'mb-4': (order.history[$index+1] && (order.history[$index+1].created_ts - history.created_ts) > 60)}`)
                .mt-2
                  div.bg-light.rounded.d-inline-block.bg-white.shadow-sm.mb-0(style='min-width: 240px')
                    .p-3
                      p.text-sms-title(style='margin-bottom: 3px') 요청사항
                      p.text-sms-summary.mb-0(style='white-space: pre; width: 300px;') {{history.origin_text}}
                      p.text-sms-title.mt-3.mb-0.bg-light.p-2(style='white-space: pre;' v-if='history.response_text')
                        img.mr-2.ml-1(src='/img/comment.svg' style='width: 20px; opacity: .3')
                        | {{history.response_text}}
                        br

                      .btn-group.btn-block.mt-2(v-if='!history.response_text')
                        a.btn.btn-default.border.rounded-0.py-2.text-primary(href='' @click.prevent='open_memo_reply(history)' style='font-size: 12px; font-weight: bold') 답변추가하기
                  div.p-2(style='font-size: 12px')
                    strong.text-dark {{ username(history.user_id)}}
                      small.text-muted.ml-1 {{history.created_at | fromNow}}

                //- div.mt-2
                  strong.text-dark {{history.user_name || '담당자명'}}
                    small.text-muted.ml-1 {{history.created_at | fromNow}}
                  br
                  p.bg-light.p-2.rounded.d-inline-block.bg-white.shadow-sm.mb-0.text-multiline {{history.origin_text}}
                  .ml-4.mt-2(v-if='history.response_text')
                    img.mr-2.ml-1(src='/img/comment.svg' style='width: 20px; opacity: .3')
                    p.bg-light.p-2.rounded.d-inline-block.bg-white.shadow-sm.mb-0
                      strong.text-multiline {{history.response_text}}
                      span.ml-2(style='opacity:.5;')
                        span.text-dark 담당자명
                          small.text-muted.ml-1 {{history.response_at | fromNow}}
                  span(v-else)
                    small.item-response-hover-action.text-primary.ml-2(@click='open_memo_reply(history)') 답변 추가


              div(v-if='history.category == `sms`' @click.stop='' :class=`{'mb-4': (order.history[$index+1] && (order.history[$index+1].created_ts - history.created_ts) > 60)}`)
                .mt-2
                  //- p.bg-light.p-2.rounded.d-inline-block.bg-white.shadow-sm.mb-0 {{history.origin_text}}
                  div.bg-light.rounded.d-inline-block.bg-white.shadow-sm.mb-0(style='min-width: 240px')
                    .px-3.pt-3
                      p.text-sms-title(style='margin-bottom: 3px') 문자전송
                      p.text-sms-summary.mb-0(style='white-space: pre; width: 300px; max-height: 2rem' v-show='!history.opened') {{history.origin_text}} ...
                      p.text-sms-summary.mb-0(style='white-space: pre; width: 300px;' v-show='history.opened') {{history.origin_text}}
                      div.pt-3(v-show='history.opened' style='font-size: 12px')
                        dl.clearfix.mb-0
                          dt.float-left 발신자번호
                          dd.float-right.mb-0(style='color:#888') {{history.origin_json.sender}}
                        dl.clearfix.mb-0
                          dt.float-left 수신자번호
                          dd.float-right.mb-0(style='color:#888') {{history.origin_json.phone}}
                        dl.clearfix.mb-0
                          dt.float-left 발송시각
                          dd.float-right.mb-0(style='color:#888') {{history.response_at | datetime}}

                    .text-center(style='')
                      a.p-2.px-3.w-100.d-block(href='#' @click.prevent='history.opened = !history.opened' style='color: #999; font-size: 12px')
                        strong.mr-1(v-if='history.opened') 상세 문자정보 접기
                        strong.mr-1(v-else) 상세 문자정보 보기
                        b-icon-chevron-up(v-if='history.opened')
                        b-icon-chevron-down(v-else)
                    //- .bg-light.border-top(style='font-size: 12px')
                      a.p-2.px-3.w-100.d-block(href='#'): strong 내용보기
                    //- .bg-light.border-top(style='font-size: 12px')
                    //-   a.p-2.px-3.w-100.d-block(href='#'): strong {{history.response_text || history.response_json && get_sms_result_text(history.response_json.result_code) || '전송대기'}}
                  //- .ml-4.mt-2
                    img.mr-2.ml-1(src='/img/comment.svg' style='width: 20px; opacity: .3')
                    p.bg-light.p-2.rounded.d-inline-block.bg-white.shadow-sm.mb-0
                      strong.item-response-hover-action.text-multiline {{history.response_text || history.response_json && get_sms_result_text(history.response_json.result_code) || '전송대기'}}
                      span.ml-2(style='opacity:.5;')
                        span.text-dark {{history.origin_json.phone}}
                          small.text-muted.ml-1 {{history.response_at | fromNow}}
                  //- .ml-4(v-else)
                  //-   small.item-response-hover-action.text-primary 답변 추가
                  div.p-2(style='font-size: 12px')
                    strong.text-dark {{ username(history.user_id)}}
                      small.text-muted.ml-1 {{history.created_at | fromNow}}
              div(v-if='history.category == `email`' @click.stop='' :class=`{'mb-4': (order.history[$index+1] && (order.history[$index+1].created_ts - history.created_ts) > 60)}`)
                .mt-2
                  div.bg-light.rounded.d-inline-block.bg-white.shadow-sm.mb-0(style='min-width: 240px')
                    .p-3
                      p.text-sms-title(style='margin-bottom: 3px') 이메일전송
                      p.text-sms-summary.mb-0(style='white-space: pre; width: 300px; max-height: 2rem' v-show='!history.opened') {{history.origin_json.subject || '(제목없음)'}}
                      //- p.text-sms-summary.mb-0(style='white-space: pre; width: 300px;' v-show='history.opened') {{history.origin_text}}
                      .btn-group.btn-block.mt-2
                        a.btn.btn-default.border.rounded-0.py-2(href='' @click.prevent='show_order_email_response(history)' style='font-size: 12px; color:#333') 내용보기
                        a.btn.btn-default.border.rounded-0.py-2.text-primary(href='' @click.prevent='show_order_email_response(history)' style='font-size: 12px; font-weight: bold' v-if='history.response_json') 답변확인
                  div.p-2(style='font-size: 12px')
                    strong.text-dark {{ username(history.user_id)}}
                      small.text-muted.ml-1 {{history.created_at | fromNow}}

                //- div.mt-2
                  strong.text-dark {{history.user_name}}
                    small.text-muted.ml-1 {{history.created_at | fromNow}}
                  br
                  p.bg-light.p-2.rounded.d-inline-block.bg-white.shadow-sm.mb-0 {{history.origin_json.body}}
                  .ml-4.mt-2(v-if='history.response_json')
                    img.mr-2.ml-1(src='/img/comment.svg' style='width: 20px; opacity: .3')
                    p.item-response-hover-action.bg-light.p-2.rounded.d-inline-block.bg-white.shadow-sm.mb-0(@click='show_order_email_response(history)')
                      strong.text-multiline() {{history.response_json.subject}}
                      br
                      span.text-body.mb-0 {{history.response_json.text}}
                      span.ml-2(style='opacity:.5;')
                        span.text-dark {{history.response_json.from}}
                          small.text-muted.ml-1 {{history.response_at | fromNow}}
                  .ml-4(v-else)
                    small (대기중)
              div(v-if='history.category == `status_color`' @click.stop='' :class=`{'mb-4': (order.history[$index+1] && (order.history[$index+1].created_ts - history.created_ts) > 60)}`)
                hr.mt-4.mr-4.mb-0.pb-0
                span.text-dark.p-1.px-2(v-if='history.origin_text == "tomato"' style='color: #888; position: relative; left: 35%; top: -14px; font-size: 12px; background-color: rgb(242, 244, 246) !important') 호출 ON
                span.text-dark.p-1.px-2(v-else style='color: #888; position: relative; left: 35%; top: -14px; font-size: 12px; background-color: rgb(242, 244, 246) !important') 호출 OFF
                //- div.mt-2
                  strong.text-dark {{history.user_name}}
                    small.text-muted.ml-1 {{history.created_at | fromNow}}
                  br
                  p.bg-light.p-2.rounded.d-inline-block.bg-white.shadow-sm.mb-0 {{history.origin_json.body}}
                  .ml-4.mt-2(v-if='history.response_json')
                    img.mr-2.ml-1(src='/img/comment.svg' style='width: 20px; opacity: .3')
                    p.item-response-hover-action.bg-light.p-2.rounded.d-inline-block.bg-white.shadow-sm.mb-0(@click='show_order_email_response(history)')
                      strong.text-multiline() {{history.response_json.subject}}
                      br
                      span.text-body.mb-0 {{history.response_json.text}}
                      span.ml-2(style='opacity:.5;')
                        span.text-dark {{history.response_json.from}}
                          small.text-muted.ml-1 {{history.response_at | fromNow}}
                  .ml-4(v-else)
                    small (대기중)


                //- div.bg-white.shadow-sm.border
                  .p-2
                    strong.text-dark {{history.user_name || '(스텝명)'}}
                    small.text-muted.float-right {{history.created_at | datetime}}
                    //- p.mb-0.item-hover-action {{history.origin_text}}
                    p.mb-0.item-hover-action.text-body {{history.origin_json.body}}
                      //- a.float-right.item-hover-action(href='#') 수정
                  .bg-light.p-1
                    div(v-if='!history.response_json')
                      img.mr-2.ml-1(src='/img/comment.svg' style='width: 20px; opacity: .3')
                      small (대기중)
                    div(v-else)
                      img.mr-2.ml-1(src='/img/comment.svg' style='width: 20px; opacity: .3')
                      small.item-response-hover-action {{history.response_json.subject}}
                      //- p.text-body {{history.response_json.text}}
            div(style='padding-bottom: 2rem')
            div#itemFooter
          .border-top
            form(@submit.prevent='send_memo(order)')
              div
                strong.text-muted.input-field-overlay: small Special Request
                textarea.form-control.text-right.rounded-0.form-field(v-model='order.form.memo' style='height: 4rem; padding-right: 127px; padding-top: 16px;' ref='order_memo_input' @blur='form_touched')
              button.btn.bg-light.border.float-right(type='submit' style='position: relative; top: -47px; right: 9px; z-index: 3; margin-bottom: -50px') 요청사항 추가
              button.btn.bg-light.border.float-right.text-center.order-color-default(type='button' style='position: relative; top: -135px; right: 9px; z-index: 3; margin-bottom: -50px; padding: 0.5rem 1rem' :class="{'order-color-active': (order.status_color)}" @click='set_status_color(order)')
                img.mr-2.ml-1.icon-light(src='/img/standing-up-man.svg' style='width: 20px; opacity: .3')
                br
                span 호출중

      .btn-group.btn-block
        button.btn.btn-light.border.py-4.text-primary(type='submit') 저장
        button.btn.btn-light.border.py-4(type='button' @click='close_order()') 닫기

  //- end table
  div
    button.d-none(v-shortkey="['/']" @shortkey='shortkey_backslash()')
  modal.p-4(name='order-memo-reply' width='500px' :height='`auto`' :scrollable='true' :focusTrap='true' :clickToClose='false')
    form(@submit.prevent='send_memo_reply(order)')
      table.table.mb-0(v-if='order_memo && order_memo.id')
        tbody
          tr
            th.bg-light 내용
            td
              p.mb-0(style='color: #555; white-space: pre-line; word-wrap: nowrap; word-break: break-all;') {{order_memo.origin_text}}
          tr
            th.bg-light 답변
            td
              //- strong.text-muted.input-field-overlay: small Special Request
              textarea.form-control.rounded-0.form-field(v-model='order_memo.reply' rows=10)
              //- textarea.form-control
      .btn-group.btn-block
        button.btn.btn-primary.rounded-0.py-4(type='submit') 저장
        button.btn.btn-light.rounded-0.border.py-4(type='button' @click='$modal.hide(`order-memo-reply`)') 닫기

  //- modal.p-4(name='order-sms-review' width='500px' :height='`auto`' :scrollable='true' :focusTrap='true' :clickToClose='false')
  //-   form(@submit.prevent='send_sms_review(order)')
  //-     table.table.mb-0(v-if='sms_config && order.form')
  //-       tbody
  //-         tr
  //-           th.bg-light 보내는 번호
  //-           td
  //-             p.mb-0(style='color: #555; white-space: pre-line; word-wrap: nowrap; word-break: break-all;') {{order.form.sms_sender_phone}}
  //-         tr
  //-           th.bg-light 받는 번호
  //-           td
  //-             p.mb-0(style='color: #555; white-space: pre-line; word-wrap: nowrap; word-break: break-all;') {{order.phone}}
  //-         tr
  //-           th.bg-light 내용
  //-           td
  //-             textarea.form-control.rounded-0.form-field(v-model='order.form.sms_filled' readonly rows=10)
  //-     .btn-group.btn-block
  //-       button.btn.btn-primary.rounded-0.py-4(type='submit') 저장
  //-       button.btn.btn-light.rounded-0.border.py-4(type='button' @click='$modal.hide(`order-memo-reply`)') 닫기

  modal.p-4(name='order-email-detail' width='500px' :height='`auto`' :scrollable='true')
    table.table.mb-0(v-if='order_email')
      tbody
        tr
          th.bg-light.py-1.text-center(colspan=2): small: strong 보낸 이메일입니다.
        tr(v-if='order_email.origin_json')
          th.bg-light(style='width: 130px') 전송 메일주소
          td {{order_email.origin_json.to}}
        tr(v-if='order_email.origin_json')
          th.bg-light(style='width: 130px') 제목
          td {{order_email.origin_json.subject}}
        tr(v-if='order_email.origin_json')
          th.bg-light(style='width: 130px') 내용
          td
            p.mb-0(style='white-space: pre-line; word-wrap: nowrap; word-break: break-all;') {{order_email.origin_json.body}}
        tr(v-if='order_email.response_json')
          th.bg-light.py-1.text-center(colspan=2): small: strong 답변받은 이메일입니다.
        tr(v-if='order_email.response_json')
          th.bg-light(style='width: 130px') 답변 메일주소
          td {{order_email.response_json.from}}
        tr(v-if='order_email.response_json')
          th.bg-light(style='width: 130px') 답변 제목
          td {{order_email.response_json.subject}}
        tr(v-if='order_email.response_json')
          th.bg-light(style='width: 130px') 답변 내용
          td
            p.mb-0(style='white-space: pre-line; word-wrap: nowrap; word-break: break-all;') {{order_email.response_json.text}}

    button.btn.btn-light.btn-block.rounded-0.border.py-4(type='button' @click='$modal.hide(`order-email-detail`)') 닫기
      //- pre {{order_email.response_json}}

  modal.py-4.modal-opened(name='add-orders' width='970px' :height='`auto`' :scrollable='true' :focusTrap='true' :clickToClose='false')
    //- pre {{orders_new}}
    h5.p-4.mb-0.border-bottom 고객 추가하기
    form.form.p-4.w-50(@submit.prevent='add_orders_append(orders_new_form)')
      .form-group
        label 상태
        select.form-control(v-model='orders_new_form.status_text' size=5 ref='orders_new_form_input')
          option(v-for='e in status_flows' :value='e') {{e}}
        small.form-text.text-muted 선택됨: {{orders_new_form.status_text}}
      .form-group
        label 고객명
        input.form-control(type='text' v-model='orders_new_form.name')
      .form-group
        label 연락처
        input.form-control(type='text' v-model='orders_new_form.phone')
      .form-group
        label 이메일
        input.form-control(type='text' v-model='orders_new_form.email')
      .form-group
        label 주문메모
        textarea.form-control(type='text' v-model='orders_new_form.memo' rows=5)
      //- pre {{orders_new_form}}
      button.btn.btn-primary(type='submit' v-show='!orders_new_form.$id') 추가
      button.btn.btn-primary(type='submit' v-show='orders_new_form.$id') 수정
      button.btn.btn-dark.ml-2(type='button' v-show='orders_new_form.$id' @click='add_orders_reset()') 취소
      //- pre {{status_flows}}
    table.table.border-bottom.shadow-sm(style='font-size: 12px')
      thead
        tr.bg-light
          th.text-center(style='width: 80px') 번호
          th(style='width: 150px') 상태
          th(style='width: 150px') 고객명
          th(style='width: 150px') 연락처
          th(style='width: 150px') 이메일
          th 주문메모
          th(style='width: 200px')
      tbody
        tr(v-for='(row, $index) in orders_new' :class="{'table-active': (row.$id && orders_new_form.editing == row.$id)}")
          td.text-center {{$index+1}}
          td {{row.status_text}}
          td {{row.name}}
          td {{row.phone}}
          td {{row.email}}
          td
            p.text-body {{row.memo}}
          td
            a.btn.btn-light.border.text-primary.btn-sm(href='#' @click.prevent='add_orders_edit(row)') 수정
            a.btn.btn-light.border.btn-sm.ml-2(href='#' @click.prevent='add_orders_delete(row)') 삭제

    //- vue-excel-editor(v-model='orders_new' :no-footer='true' :allow-add-col='false' :new-if-bottom='true' :enterToSouth='true' @update='orders_add_changed' :no-paging='true' :no-header-edit='true')
      vue-excel-column(field='status_text' label='상태' type='select' :options='orders_new_options_status' width='120px' text-align='center')
      vue-excel-column(field='name' label='고객명' width='200px')
      vue-excel-column(field='phone' label='연락처' width='200px')
      vue-excel-column(field='email' label='이메일' width='200px')
      vue-excel-column(field='memo' label='주문메모' width='600px')

    .btn-group.btn-block.border-top
      button.btn.rounded-0.py-4.btn-primary(type='button' @click='add_orders_complete()') 저장 ({{orders_new.length}}건)
      button.btn.rounded-0.py-4.btn-light(type='button' @click='$modal.hide(`add-orders`)') 닫기

  modal(name='checklist-detail' width='500px' :height='`auto`' :scrollable='true')
    .p-4
      h5 예약완료문자
        span.text-muted.ml-2 체크리스트
      table.table.mt-4.border.shadow-sm(style='font-size: 12px')
        thead
          tr.bg-light
            th 고객명
            th 내용
            th 액션
            th
        tbody
          tr
            td 김호지
            td 예약완료시 문자발송해주세요.
            td
              button.btn.btn-dark.btn-sm(onclick='alert(`주문내역 모달띄우고, 문자발송 탭으로 이동, 내용은 채워져있음`)') 클릭하여 진행
            td
              a(href='#') 조회
          tr
            td 김호지
            td 예약완료시 문자발송해주세요.
            td
              button.btn.btn-light.border.btn-sm(disabled) 완료
            td
              a(href='#') 조회
  modal(name='checklist-detail2' width='500px' :height='`auto`' :scrollable='true')
    .p-4
      h5 수량체크
        span.text-muted.ml-2 체크리스트
      table.table.mt-4.border.shadow-sm(style='font-size: 12px')
        thead
          tr.bg-light
            th 고객명
            th 내용
            th 액션
            th
        tbody
          tr
            td 김호지
            td 크리스마스 월컴 기프트 수량체크요망
            td
              button.btn.btn-dark.btn-sm(onclick='alert(`주문내역 모달띄우고, 요청사항으로 이동, 생성된 SpecialRequest에 답변 추가해야함`)') 클릭하여 진행
            td
              a(href='#') 조회
          tr
            td 김호지
            td 크리스마스 월컴 기프트 수량체크요망
            td
              button.btn.btn-light.border.btn-sm(disabled) 완료
            td
              a(href='#') 조회
          tr
            td 김호지
            td 크리스마스 월컴 기프트 수량체크요망
            td
              button.btn.btn-light.border.btn-sm(disabled) 완료
            td
              a(href='#') 조회
  //- order-detail

  //- div(style='position: fixed; bottom: 0px; left: 0px; z-index: 999; width:200px')
    .card.shadow.p-2.m-2.card-checklist(style='font-size: 12px' @click='$modal.show(`checklist-detail`)')
      strong 예약완료문자
        span.float-right.text-muted {{ (Math.floor(rows.length/2)) }}건 남음
      b-progress.border(class="mt-2" :max="rows.length" show-value)
        b-progress-bar(:value="Math.floor(rows.length/2)" variant="success")
        b-progress-bar(:value="Math.ceil(rows.length/2)" variant="light")
  //- div(style='position: fixed; bottom: 0px; left: calc(200px); z-index: 999; width:200px')
    .card.shadow.p-2.m-2.card-checklist.border-success(style='font-size: 12px' @click='$modal.show(`checklist-detail2`)')
      strong 수량체크
        span.float-right.text-muted 완료
      b-progress(class="mt-2" :max="rows.length" show-value)
        //- b-progress-bar.text-dark(:value="rows.length" variant="light")
        //- b-progress-bar.text-dark(:value="rows.length" variant="light")
        //- b-progress-bar.text-light(:value="rows.length" variant="dark")
        b-progress-bar(:value="rows.length" variant="success")
        b-progress-bar(:value="0" variant="light")
</template>

<script>

// import OrderDetail from './OrderDetail'
import flatten from 'lodash/flatten'

import { Mentionable } from 'vue-mention'

import NProgress from 'nprogress'

import moment from 'moment'

const ORDER_FORM = {
  sms_input: '',
  sms_result: '',
  sms_sender_phone: '',
  subject: '',
  email: '',
  memo: '',
}

export default {
  name: 'order-detail',
  props: ['sf_id', 'property_id', 'order_id'],
  components: {
    // OrderDetail,
    Mentionable,
  },
  computed: {
    status_flows() {
      return this.$store.state.status_flows
    },
    users_by_id() {
      return this.$store.state.users_by_id
    },
  },
  // created() {
  //   console.log(333)
  //   window.addEventListener('beforeunload', event => {
  //     console.log('before_unload')
  //     // if(this.has_changed()) {
  //     //   event.preventDefault()
  //     //   event.returnValue = '저장안한 수정내용이 있습니다. 페이지를 나갈까요?'
  //     // }
  //     event.preventDefault()
  //     event.returnValue = '저장안한 수정내용이 있습니다. 페이지를 나갈까요?'
  //   })
  //   // window.addEventListener('beforeunload', () => console.log(32432423))
  // },

  data() {
    return {
      sms_mention_users: [
        {
          value: '고객명',
          label: '고객명 Name'
        },
        {
          value: '이메일주소',
          label: '이메일주소 Email'
        },
      ],
      sms_mention_issues: [
        {
          value: '감사문구',
          label: '감사문구',
          searchText: '감사문구',
        },
        {
          value: '환불가능',
          label: '환불가능',
          searchText: '환불가능',
        },
      ],
      columns: [
        // {
        //   label: '',
        //   field: 'action',
        //   // width: '0px',
        //   tdClass: () => {
        //     return this.columnStyle(...arguments) + ' column-action'
        //   },
        //   sortable: false,
        // },
        {
          label: '번호',
          field: 'id',
          width: '50px',
          sortable: false,
          tdClass: (row) => {
            if (row.status_color) {
              return 'order-status-bordered-' + row.status_color
            } else {
              return 'order-status-default'
            }
          },
        },
        {
          label: '상태',
          field: 'status_text',
          // width: '50px',
          tdClass: this.columnStyle,
          // filterOptions: {
          //   enabled: true,
          //   filterDropdownItems: ['와우']
          // }
        },
        {
          label: '주문메모',
          field: 'memo',
          tdClass: this.columnStyle,
          sortable: false,
        },
        {
          label: '고객명',
          field: 'name',
          width: '150px',
          tdClass: this.columnStyle,
        },
        {
          label: '고객메모',
          field: 'customer_memo',
          tdClass: this.columnStyle,
          sortable: false,
        },
        {
          label: '연락처',
          field: 'phone',
          tdClass: this.columnStyle,
        },
        {
          label: '이메일',
          field: 'email',
          tdClass: this.columnStyle,
          sortable: false,
        },
        {
          label: '생성일',
          field: 'created_at',
          tdClass: this.columnStyle,
        },
        {
          label: '변경일',
          field: 'updated_at',
          tdClass: this.columnStyle,
        },
      ],
      rows: [],
      total_count: 0,
      paginationOptions: {
        enabled: true,
        mode: 'pages',
        perPage: 10,
        position: 'bottom',
        setCurrentPage: 1,
        perPageDropdown: [10, 30, 100],
        dropdownAllowAll: false,
      },
      selectOptions: {
        enabled: true,
        selectOnCheckboxOnly: true,
        // selectionInfoClass: 'custom-class',
        // selectionText: 'rows selected',
        // clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      },
      isLoading: false,
      sortOptions: {
        enabled: true,
        initialSortBy: [
          // {
          //   field: 'created_at',
          //   type: 'desc',
          // },
        ]
      },
      searchOptions: {},
      serverParams: {
        columnFilters: {
        },
        sort: [
          // {
          //   field: 'created_at',
          //   type: 'desc',
          // }
        ],
        page: 1,
        perPage: 10
      },

      order: {},
      order_original: {},
      order_loaded: false,
      order_detail_tab: 0,
      order_email: {},
      order_memo: {},
      d: '',

      sms_config: {
        numbers: [],
      },
      sms_mention_items: [],

      orders_new: [],
      orders_new_form: {
        status_text: '',
        name: '',
        phone: '',
        email: '',
        memo: '',
      },
      a: {a: 0},
    }
  },

  async mounted() {
    // this.$modal.show('checklist-detail')
    // window.addEventListener('beforeunload', (event) => {
    //   // Cancel the event as stated by the standard.
    //   event.preventDefault();
    //   // Older browsers supported custom message
    //   event.returnValue = '';
    // });
    window.addEventListener('beforeunload', this.before_unload)

    //   console.log('2342342342343232')
    //   // Cancel the event as stated by the standard.
    //   event.preventDefault();
    //   // Older browsers supported custom message
    //   event.returnValue = '333';
    // });

    console.log(this.sf_id, this.property_id, this.order_id)
    console.log('order detail emit', this.sf_id)
    // this.$emit('open-modal', this.order_id)
    if (status == 'all') {
      // this.onSortChange([{
      //   field: 'updated_at',
      //   type: 'desc',
      // }])
    }
    this.load_order()
    this.load_sms()

    // this.add_orders()
  },

  updated() {
    if (window.RequireFooterScroll) {
      if (window.RequireFooterScrollInit) {
        document.querySelector('#itemFooter')?.scrollIntoView()
        // setTimeout(() => {
        //   document.querySelector('#itemFooter').scrollIntoView()

        // }, 300);
        window.RequireFooterScrollInit = false
      } else {
        document.querySelector('#itemFooter')?.scrollIntoView({behavior: "smooth"})
      }
      // setTimeout(() => {
      // }, 1000);
      window.RequireFooterScroll = false
    }
  },

  methods: {
    username(id) {
      return this.users_by_id[id]?.name || '(이름없음)'
    },
    onOpen (key) {
      this.sms_mention_items = key === '@' ? this.sms_mention_users : this.sms_mention_issues
    },

    async load_sms() {
      try {
        const r = await this.$http.get(`/v1/property/${this.property_id}/sms_config`)
        this.sms_config = r.data.sms_config
        if (!this.sms_config.numbers) this.sms_config.numbers = []
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: 'failed to load: sms config',
        })
      }
    },
    async load_order() {
      const status = this.sf_id
      try {
        let rows

        if (status == 'all') {
          this.paginationOptions.enabled = true
          this.searchOptions = {
            enabled: true,
            trigger: 'enter',
          }
          // this.sortOptions = Object.assign({}, this.sortOptions, {
          //   enabled: true,
          //   initialSortBy: [
          //     {
          //       field: 'updated_at',
          //       type: 'desc',
          //     },
          //   ]
          // })
          // this.updateParams({
          //   sort: [{
          //     field: 'updated_at',
          //     type: 'desc',
          //   }],
          // });
          // this.serverParams = Object.assign({}, this.serverParams, {
          //   columnFilters: {
          //   },
          //   sort: [{
          //     field: 'updated_at',
          //     type: 'desc',
          //   }],
          //   page: 1,
          //   perPage: 10
          // })

          const r = await this.$http.get(`/v1/property/${this.property_id}/orders/latest`, {
            params: this.serverParams,
          })
          if (r?.data?.message != 'ok') throw new Error('가져오기 실패')
          rows = r.data.rows
          this.total_count = r.data.counts.count
        } else {
          this.paginationOptions = {
            enabled: false,
            // mode: 'pages',
            // perPage: 10,
            // position: 'bottom',
            // setCurrentPage: 1,
            // perPageDropdown: [10, 30, 100],
            // dropdownAllowAll: false,
          }
          this.searchOptions = {
            enabled: true,
            trigger: 'enter',
            // searchFn: (row, col, cellValue, searchTerm) => {
            //   return String(cellValue).includes(searchTerm);
            // },
          }
          console.log(this.paginationOptions)
          const r = await this.$http.get(`/v1/property/${this.property_id}/orders`, {
            params: {
              status,
              ...this.serverParams,
            }
          })
          if (r?.data?.message != 'ok') throw new Error('가져오기 실패')
          rows = r.data.rows
        }

        this.rows = rows.map(e => {
          e.history = []
          e.loaded = false
          e.form = Object.assign({}, ORDER_FORM)

          return e
        })
        this.order_loaded = true

        this.order_loading = true
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.load_order();
    },

    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage});
      this.load_order();
    },

    onSortChange(params) {
      // console.log(params)
      // const sort = params.map(e => {
      //   return {
      //     type: e.type,
      //     field: e.field,
      //   }
      // })
      this.updateParams({
        sort: params,
      });
      this.load_order();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.load_order();
    },

    onSearch(params) {
      console.log(this.sf_id)
      this.updateParams(params);
      this.load_order();
    },

    columnStyle (row) {
      if (row.status_color) {
        return 'order-field order-status-' + row.status_color
      } else {
        return ''
      }
    },

    // open_order_detail(order) {
    //   this.$router.push({
    //     path: `/property/${this.property_id}/order/${this.sf_id}/${order.id}`,
    //   })
    // }
    did_load_order() {
      window.RequireFooterScroll = true
    },
    keep_original_order() {
      this.order_original = Object.assign({}, {
        name: this.order.name,
        email: this.order.email,
        phone: this.order.phone,
        order_code: this.order.order_code,
        customer_memo: this.order.customer_memo,
        memo: this.order.memo,
      })
      window.FormTouched = 'N'
    },
    async reload_order(order_id) {
      console.log('NOT IN STATE, NEW LOAD: ')
      try {
        const r = await this.$http.get(`/v1/property/${this.property_id}/orders/${order_id}`)
        if (r?.data?.message != 'ok') throw new Error('주문을 찾을수 없습니다. (20)')
        // this.order =
        // console.log(r.data)
        // this.$set('this.order', Object.assign({}, this.order, { ...r.data.order }))
        this.order = Object.assign({}, this.order, { ...r.data.order })
        this.order.history = r.data.order_history
        if (!this.order.form) this.order.form = Object.assign({}, ORDER_FORM)
        this.did_load_order()
      } catch (error) {
        console.log(error)
      }
    },
    async open_modal(order_id) {
      console.log('window.sessionStorage.prevent_order_reload: ', window.sessionStorage.prevent_order_reload)
      if (window.sessionStorage.prevent_order_reload == 'Y') {
        window.sessionStorage.prevent_order_reload = 'N'
        return
      }

      try {
        window.RequireFooterScrollInit = true

        console.log('open_modal', order_id)
        console.log(this.rows.length)
        const found = this.rows.filter(e => e.id == order_id)
        if (!found.length) {
          await this.reload_order(order_id)
        } else {
          this.order = Object.assign({}, found[0])
          this.load_order_history(this.order.id)
        }

        this.keep_original_order()

        this.$modal.show('order-detail')
        if (this.sms_config?.numbers?.length) {
          this.order.form.sms_sender_phone = this.sms_config.numbers[0].number
        }
        window.sessionStorage.last_order_id = order_id
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async load_order_history(order_id) {
      try {
        console.log('load_order_history', order_id)
        const r = await this.$http.get(`/v1/property/${this.property_id}/orders/${order_id}`)
        if (r?.data?.message != 'ok') throw new Error('주문내역을 찾을수 없습니다. (30)')
        this.order.history = r.data.order_history.map(e => {
          e.opened = false

          return e
        })
        this.did_load_order()
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    close_modal(event) {
      console.log('close_modal from OrderDetail')
      // this.$modal.hide('order-detail')
      // this.$router.push({
      //   path: `/property/${this.property_id}/order/${this.sf_id}`,
      // })
    },
    has_changed() {
      const name_changed = this.order_original.name != this.order.name
      const phone_changed = this.order_original.phone != this.order.phone
      const order_code_changed = this.order_original.order_code != this.order.order_code
      const email_changed = this.order_original.email != this.order.email
      const memo_changed = this.order_original.memo != this.order.memo
      const customer_memo_changed = this.order_original.customer_memo != this.order.customer_memo
      const form_changed = this.order.form.sms_input.length || this.order.form.subject.length || this.order.form.email.length || this.order.form.memo.length
      if (name_changed || phone_changed || order_code_changed || email_changed || memo_changed || customer_memo_changed || form_changed) {
        return true
      }
      return false
    },
    before_close(event) {
      console.log(`modal@before_close order_id=${this.order_id}`)

      if (!this.order_id) {
        return
      }
      if (window.FormTouched == 'Y') {
        if(confirm('저장안한 수정내용이 있습니다. 창을 닫으시겠습니까?')) {
          // continue
          window.FormTouched = 'N'
        } else {
          return event.cancel()
        }
      }
      this.$router.push({
        path: `/property/${this.property_id}/order/${this.sf_id}/`,
      })
    },
    close_order() {
      this.$modal.hide(`order-detail`)
    },
    before_unload(event) {
      console.log('before_unload')
      if(window.FormTouched == 'Y') {
        event.preventDefault()
        event.returnValue = '저장안한 수정내용이 있습니다. 페이지를 나갈까요?'
      }
    },

    opened() {
      this.$refs['order-input']?.focus()

      // const a = this.order.history.filter(e => e.id == 16)
      // this.show_order_email_response(a[0])
    },
    async remove_orders() {
      const rows = this.$refs.table.selectedRows
      if (!confirm(`${rows.length}건을 삭제할까요?`)) return false

      console.log(rows)

      try {
        const r = await this.$http.post(`/v1/property/${this.property_id}/orders/bulk_cancel`, {
          // ids: rows.map(e => e.id),
          orders: rows.map(e => {
            return {
              id: e.id,
              status_text: e.status_text,
            }
          })
        })
        if (r?.data?.message != 'ok') throw new Error('주문건 삭제 실패')

        this.load_order()
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },

    add_orders() {
      if (window.localStorage.ORDERS_ADD) {
        try {
          const d = JSON.parse(window.localStorage.ORDERS_ADD)
          this.orders_new = d.data
        } catch (error) {
          console.log("failed to parse window.localStorage.ORDERS_ADD")
        }
      }
      this.add_orders_reset()

      this.$modal.show('add-orders')
    },
    orders_new_options_status() {
      return this.status_flows
    },
    orders_add_changed() {
      console.log('save to local')
      window.localStorage.ORDERS_ADD = JSON.stringify({
        version: 2,
        data: this.orders_new,
      })
      this.$set(this.a, 'a', 1)
    },
    add_orders_append(form) {
      if (form.$id) {
        this.orders_new = this.orders_new.map(e => {
          if (e.$id == form.$id) {
            return form
          }
          return e
        })
      } else {
        this.orders_new.push(Object.assign({}, form))
        form = {
          status_text: '',
          name: '',
          phone: '',
          email: '',
        }
      }
      this.orders_add_changed()
      this.add_orders_reset()
    },
    add_orders_edit(row) {
      this.orders_new_form = Object.assign({}, row)
      this.orders_new_form.editing = row.$id
      setTimeout(() => {
        this.$refs.orders_new_form_input?.focus()
      }, 0);
    },
    add_orders_delete(row) {
      if(!confirm('삭제할까요?')) return false
      this.orders_new = this.orders_new.filter(e => e.$id != row.$id)
      this.orders_add_changed()
    },
    add_orders_reset() {
      this.orders_new_form = {}
      if (this.sf_id == 'all') {
        this.orders_new_form.status_text = this.status_flows[0] || ''
      } else {
        this.orders_new_form.status_text = this.sf_id
      }

      setTimeout(() => {
        this.$refs.orders_new_form_input?.focus()
      }, 300);
    },
    async add_orders_complete() {
      let errors = []
      let saved = 0
      let not_saved = 0
      const orders_left = []
      for(const e of this.orders_new) {
        try {
          const r = await this.$http.post(`/v1/property/${this.property_id}/orders`, e)
          if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '오류')

          saved += 1
        } catch (error) {
          errors.push(r?.data?.message || '오류')
          not_saved += 1

          orders_left.push(e)
        }
      }

      this.orders_new = orders_left
      this.orders_add_changed()

      console.log({errors, saved, not_saved})

      if (saved) {
        this.$store.dispatch('status flows count', this.property_id)
        this.load_order()
      }

      if (errors.length) {
        this.$modal.show('dialog', {
          title: '알림',
          text: `저장 성공${saved}건, 실패${not_saved}건<br /><br />${errors.join('<br />')}`
        })
      } else {
        this.$modal.show('dialog', {
          title: '알림',
          text: `저장했습니다.`
        })
      }

      this.$modal.hide('add-orders')
    },






    async set_status(order, st) {
      try {
        const r = await this.$http.post(`/v1/property/${this.property_id}/orders/${order.id}/set_status`, {
          status_text: st,
        })
        if (r?.data?.message != 'ok') throw new Error('상태변경 실패')

        order.status_text = st

        // this.$modal.show('dialog', {
        //   title: '알림',
        //   text: '변경했습니다.',
        // })
        this.$bvToast.toast(`주문상태를 변경했습니다. ${moment().format('HH:mm:ss')}`, {
          title: `알림`,
          variant: 'default',
          solid: true
        })
        this.load_order()
        this.$store.dispatch('status flows count', this.property_id)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async set_status_color(order) {
      try {
        const status_color = order.status_color == 'tomato' ? '' : 'tomato'
        const r = await this.$http.post(`/v1/property/${this.property_id}/orders/${order.id}/set_status_color`, {
          status_color,
        })
        if (r?.data?.message != 'ok') throw new Error('호출변경 실패')

        this.order.status_color = status_color
        this.load_order()
        this.reload_order(order.id)

        this.$refs['order_memo_input']?.focus()

        this.$store.dispatch('status flows count', this.property_id)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async update_order(order) {
      try {
        const r = await this.$http.put(`/v1/property/${this.property_id}/orders/${order.id}`, order)
        if (r?.data?.message != 'ok') throw new Error('저장 실패')

        // this.$modal.show('dialog', {
        //   title: '알림',
        //   text: '변경했습니다.',
        // })
        this.$bvToast.toast(`주문내용을 변경했습니다. ${moment().format('HH:mm:ss')}`, {
          title: `알림`,
          variant: 'default',
          solid: true
        })
        this.load_order()
        this.keep_original_order()
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async send_sms(order) {
      try {
        // this.$modal.show('order-sms-review')
        const text = `발신자: ${order.form.sms_sender_phone}\n수신자: ${order.phone}\n\n${order.form.sms_result}\n\n 발송하시겠습니까?`
        if(!confirm(text)) return false
        const r = await this.$http.post(`/v1/property/${this.property_id}/orders/${order.id}/send_sms`, {
          text: order.form.sms_result,
        })
        if (r?.data?.message != 'ok') throw new Error('문자발송 실패')

        order.form.sms_input = ''
        order.form.sms_result = ''

        this.load_order_history(order.id)

        this.$modal.show('dialog', {
          title: '알림',
          text: '문자를 보냈습니다.',
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async send_email(order) {
      try {
        const r = await this.$http.post(`/v1/property/${this.property_id}/orders/${order.id}/send_email`, {
          text: order.form.email,
          subject: order.form.subject,
        })
        if (r?.data?.message != 'ok') throw new Error('메일발송 실패')

        this.load_order_history(order.id)

        this.$modal.show('dialog', {
          title: '알림',
          text: '메일을 보냈습니다.',
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async send_memo(order) {
      try {
        const r = await this.$http.post(`/v1/property/${this.property_id}/orders/${order.id}/send_memo`, {
          text: order.form.memo,
        })
        if (r?.data?.message != 'ok') throw new Error('메모추가 실패')

        this.reload_order(order.id)
        this.load_order()

        // this.$modal.show('dialog', {
        //   title: '알림',
        //   text: '메모를 추가했습니다.',
        // })
        order.form.memo = ''
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async send_memo_reply(order) {
      try {
        const r = await this.$http.post(`/v1/property/${this.property_id}/orders/${order.id}/history/${this.order_memo.id}/reply_memo`, {
          text: this.order_memo.reply,
        })
        if (r?.data?.message != 'ok') throw new Error('요청사항 답변 저장 실패')

        this.reload_order(order.id)

        // this.$modal.show('dialog', {
        //   title: '알림',
        //   text: '메모를 추가했습니다.',
        // })
        this.order_memo.reply = ''
        this.$modal.hide('order-memo-reply')
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },

    get_sms_result_text(code) {
      return {
        '200': '수신완료',
      }[code] || '발송실패'
    },

    show_order_email_response(order_history) {
      this.order_email = order_history
      this.$modal.show('order-email-detail')
    },
    open_memo_reply(history) {
      this.$modal.show(`order-memo-reply`)
      this.order_memo = history
    },
    sms_result() {
      console.log(2)
      let text = '' + this.order.form.sms_input
      text = text.replace('@고객명', this.order.name)
      text = text.replace('@이메일주소', this.order.email)

      text = text.replace('#환불가능', `[환불안내] 포장후 택배사 방문수거 예정입니다. 1-3일내 수거.`)
      text = text.replace('#감사문구', `행복한 하루 되시길 바랍니다. 감사합니다.\n[문화를 사랑하는 YES24]`)
      this.$nextTick(() => {
        this.order.form.sms_result = text
      })
    },

    form_touched() {
      if (this.has_changed()) {
        window.FormTouched = 'Y'
      } else {
        window.FormTouched = 'N'
      }
      console.log(window.FormTouched)
    },

    shortkey_backslash() {
      // modal opened
      if (document.querySelector('.modal-opened')) return

      const $input = document.querySelector('.vgt-global-search__input input')
      if ($input) $input?.focus()

      // console.log(1)
    }
  },

  beforeDestroy() {
    // this.$emit('close-modal')
  },
  // destroy() {
  //   window.removeEventListener('beforeunload')
  // },
  beforeRouteUpdate (to, from, next) {
    console.log('@beforeRouteUpdate')
    if (window.FormTouched == 'Y') {
      if(confirm('저장안한 수정내용이 있습니다. 창을 닫으시겠습니까?')) {
        window.FormTouched = 'N'
        return next()
      } else {
        NProgress.done()
        return next(false)
      }
    }
    next()
  },

  watch: {
    'sf_id'(to) {
      this.load_order()
    },
    'order_id'(to) {
      if (!to) {
        this.$modal.hide('order-detail')
        // this.$router.push({
        //   path: `/property/${this.property_id}/order/${this.sf_id}`,
        // })
      }
      // console.log({to})
      // this.load_order()
    },
  }
}
</script>
